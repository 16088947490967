import React from 'react'
import { graphql } from "gatsby"
import { kebabCase } from 'lodash'

import * as styles from '../styles/sitemap.module.scss'

import Layout from "../components/layout"
import { createCategoryNameMap } from '../components/functions/createconst'

const SiteMap = ({location, data})=>{
    const cat1list = data.category1group.group
    const cat2list = data.category2group.group
    const postlist = data.allMdx.nodes
    let sortedpostlist = []
    let sortedcat1list = []
    let sortedcat2list = []



    for(let i = 0; i < cat1list.length; i++){
        sortedcat1list.push(cat1list[i])
        for(let j = 0; j < cat2list.length; j++){
            sortedcat2list.push(cat2list[j])
            for(let k = 0; k < postlist.length; k++){
                if(postlist[k].frontmatter.category1 === cat1list[i].fieldValue &
                postlist[k].frontmatter.category2 === cat2list[j].fieldValue){
                    sortedcat2list.push([postlist[k].frontmatter.title, postlist[k].fields.slug])
                }
            }
            if(sortedcat2list.length !== 1){
                sortedcat1list.push(sortedcat2list)
            }
            sortedcat2list = []
        }
        sortedpostlist.push(sortedcat1list)
        sortedcat1list = []
    }

    const MakeSiteMapSubList = ({postlist, cat1slug}) => {
        const categorynamemap = createCategoryNameMap()
        const items = []

        for(let i = 0; i < postlist.length; i++){
            let cat2post = postlist[i]
            let cat2title = categorynamemap[cat2post[0].fieldValue]
            let sortedpost =[]
            for(let j = 1; j < cat2post.length; j++){
                sortedpost.push(cat2post[j])
            }
            
            items.push(
                <div key={cat2post}>
                    <a href = {`/${kebabCase(cat1slug)}/${kebabCase(cat2post[0].fieldValue)}`} >
                        <li>{cat2title}</li>
                    </a>
                    <ul className={styles.postul}>
                        {sortedpost.map((post) =>
                            <a href = {`/${kebabCase(cat1slug)}/${kebabCase(cat2post[0].fieldValue)}${post[1]}`} >
                                <li>{post[0]}</li>
                            </a>
                        )}
                    </ul>
                </div>
                
            )
        }

        return(
            <ul className={styles.cat2ul}>
                {items}
            </ul>
        )
    }


    const MakeSiteMapList = ({postlist}) => {
        const categorynamemap = createCategoryNameMap()
        const items = []
        const cat1list = []

        for(let i = 0; i < postlist.length; i++){
            let cat1 = postlist[i]
            cat1list.push(cat1[0])
        }

        for(let k = 0; k < cat1list.length; k++){
            let cat2 = postlist[k]
            let cat2post = []

            for(let l = 1; l < cat2.length; l++){
                cat2post.push(cat2[l])
            }
            items.push(
                <a href = {`/${kebabCase(cat1list[k].fieldValue)}`}>
                    <li>{categorynamemap[cat1list[k].fieldValue]}</li>
                    <MakeSiteMapSubList postlist ={cat2post} cat1slug = {cat1list[k].fieldValue}></MakeSiteMapSubList>
                </a>
            )
        }

        return(
            <ul className={styles.cat1ul}>
                {items}
            </ul>
        )
    }


    return(
        <Layout location = {location} title = "profile">
            <div className = {styles.sitemapwrapper}>
                <h1>サイトマップ</h1>
                <MakeSiteMapList postlist={sortedpostlist}></MakeSiteMapList>
            </div>
        </Layout>

    )
}
export default SiteMap

export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    category1group: allMdx(limit: 2000) {
        group(field: frontmatter___category1) {
            fieldValue
            totalCount
        }
    }
    category2group: allMdx(limit: 2000) {
        group(field: frontmatter___category2) {
            fieldValue
            totalCount
        }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
            fields {
                slug
            }
            frontmatter {
                category1
                category2
                title
            }
        }
    }
}
`
